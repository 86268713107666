import { Controller } from "stimulus"
import objectToFormData from "object-to-formdata"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  cart() {
    var req = []
    var qtys = Array.prototype.slice.call(document.querySelectorAll('.cart-table__row')).splice(1)
    var id = this.data.get("index")
    qtys.map(function(value) {
      req.push({
        "quantity": value.querySelectorAll('.form-control.input-number__input')[0].value,
        "id": value.lastElementChild.firstElementChild.dataset.product
      })
    })
    const options = {
      indices: false
    }
    let formData = objectToFormData({shop_order: {order_products_attributes: req}}, options)
    var data = formData
    Rails.ajax({
      type: "put",
      url: `/shop_orders/${id}`,
      data: data
    })
  }
}
