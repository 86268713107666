import { Controller } from "stimulus"
import * as currency from "currency.js"
import { filterInt } from "../helpers/application_helper.js"

export default class extends Controller {
  static targets = [ "price", "priceCents" ]

  currency_opts() {
    let opts = {
      formatWithSymbol: true,
      separator: ' ',
      decimal: ',',
      //pattern: `# !`,
      symbol: '€'
    }
    return opts
  }

  connect() {
    this.formatField()
  }

  formatField() {
    this.priceTarget.value = currency(this.priceTarget.value, this.currency_opts()).format()
  }

  updateCentsField(event) {
    if (this.priceTarget.value === "") {
      this.priceTarget.value = "€"
    }

    this.priceCentsTarget.value = currency(this.priceTarget.value, this.currency_opts()).intValue
  }
}
