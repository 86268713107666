import { Controller } from "stimulus"
import { createCookie } from "../helpers/cookies_helper.js"
import { filterInt } from "../helpers/application_helper.js"

export default class extends Controller {
  static targets = [ "announcement" ]

  hideAnnouncement() {
    let days = 0
    if (isNaN(filterInt(this.data.get("announcementDays")))) {
      days = 365
    } else {
      days = filterInt(this.data.get("announcementDays"))
    }

    createCookie(this.data.get("announcementKey"), "hidden", days)
  }
}
