import { Controller } from "stimulus"
import { filterInt } from "../helpers/application_helper.js"

export default class extends Controller {
  static targets = [ "quantity", "inputForm", "spanForm" ]

  changeToNumberInput() {
    this.inputFormTarget.type = "number"
    this.inputFormTarget.className = "input is-rounded"

    this.spanFormTarget.style.display = "none"
    this.inputFormTarget.focus()
  }

  changeToHiddenInput() {
    this.inputFormTarget.type = "hidden"
    this.spanFormTarget.removeAttribute("style")
    this.quantityTarget.textContent = this.maxValueDecider(filterInt(this.inputFormTarget.value))
    this.exchangeButtonToUpdate()
  }

  increaseQuantity() {
    let previousQuantity = filterInt(this.quantityTarget.textContent.trim())

    if (isNaN(previousQuantity)) {
      this.quantityTarget.textContent = this.inputFormTarget.min
    } else {
      this.quantityTarget.textContent = this.maxValueDecider(previousQuantity + 1)
    }
    this.updateInput(filterInt(this.quantityTarget.textContent))
  }

  decreaseQuantity() {
    let previousQuantity = filterInt(this.quantityTarget.textContent.trim())

    if (isNaN(previousQuantity)) {
      this.quantityTarget.textContent = this.inputFormTarget.min
    } else if (previousQuantity < this.inputFormTarget.min) {
      this.quantityTarget.textContent = this.inputFormTarget.min
    } else {
      this.quantityTarget.textContent = this.maxValueDecider(previousQuantity - 1)
    }
    this.updateInput(filterInt(this.quantityTarget.textContent))
  }

  updateInput(quantity) {
    this.inputFormTarget.value = quantity
    this.exchangeButtonToUpdate()
  }

  maxValueDecider(value) {
    let maxValue = this.inputFormTarget.max
    if(value > maxValue) {
      return maxValue
    } else {
      return value
    }
  }

  exchangeButtonToUpdate() {
    document.querySelector("#init-checkout").classList.add("is-hidden")
    document.querySelector("#update-cart-page").classList.remove("is-hidden")
  }
}
