import { Controller } from "stimulus"
import objectToFormData from "object-to-formdata"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  updateCart() {
    let req = []
    let qtys = document.querySelectorAll('.flat-card.is-auto.cart-card')
    let id = this.data.get("index")
    Array.prototype.slice.call(qtys).map(function(value, index) {
      req.push({
        "quantity": value.querySelectorAll('#quantity')[0].value,
        "id": value.querySelectorAll('.action-link.is-remove.has-simple-popover')[0]
          .firstElementChild.dataset.product
      })
    })
    const options = {
      indices: false
    }
    let formData = objectToFormData({shop_order: {order_products_attributes: req}}, options)
    let data = formData
    Rails.ajax({
      type: "put",
      url: `/juniper/aloe/shop_orders/${id}`,
      data: data
    })
  }
}
