import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "country", "zone" ]

  connect() {
    this.zoneTarget.previousElementSibling.classList.remove("select")
    this.zoneTarget.parentElement.classList.remove("select")

    // TODO: Add chosen-js event listener and refactor
    // TODO: For some reason addEventListener does not work here
    //       Maybe bind issues or Chosen-js plus jquery issues
    $("#" + this.countryTarget.id).on('change', () => this.updateZone())
    this.countryTarget.dispatchEvent(new Event("change"))
  }

  updateZone() {
    var country = this.countryTarget.value
    Rails.ajax({
      type: "get",
      url: "/shipping_zone_search",
      data: "q[country_cont]=" + country,
      success: (data) => {
        while (this.zoneTarget.firstChild) {
          this.zoneTarget.removeChild(this.zoneTarget.firstChild);
        }
        data.forEach((item) => {
          var optionInput = document.createElement("option")
          optionInput.setAttribute("value", item.id)
          optionInput.insertAdjacentText("afterbegin", item.name)
          this.zoneTarget.appendChild(optionInput)
          this.zoneTarget.dispatchEvent(new Event("chosen:updated"))
        })
      }
    })
  }
}
