//function importAll (r) {
//  r.keys().forEach(r);
//}
//
//importAll(require.context('./stroyka', true, /\.eot$/));
//importAll(require.context('./stroyka', true, /\.ttf$/));
//importAll(require.context('./stroyka', true, /\.woff$/));
//importAll(require.context('./stroyka', true, /\.svg$/));
//importAll(require.context('./stroyka', true, /\.css$/));

import "./stroyka/stroyka.svg"
import "./stroyka/stroyka.ttf"
import "./stroyka/stroyka.woff"
import "./stroyka/stroyka.css"
