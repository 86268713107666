import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "country", "zone" ]

  connect() {
    var country = this.countryTarget.options[this.countryTarget.selectedIndex].value
    //var zone = this.zoneTarget.firstChild.value
    if (country != "") {
      this.requestZone(country)
    }
  }

  updateZone() {
    var value = this.countryTarget.options[this.countryTarget.selectedIndex].value
    this.requestZone(value)
  }

  requestZone(country) {
    Rails.ajax({
      type: "get",
      url: "/shipping_zone_search",
      data: "q[country_cont]=" + country,
      success: (data) => {
        this.refreshDropdownValues(data)
      }
    })
  }

  refreshDropdownValues(data) {
    while (this.zoneTarget.firstChild) {
      this.zoneTarget.removeChild(this.zoneTarget.firstChild);
    }
    // update a Stimulus Target
    data.forEach((item) => {
      var optionInput = document.createElement("option")
      optionInput.setAttribute("value", item.id)
      optionInput.insertAdjacentText("afterbegin", item.name)
      this.zoneTarget.appendChild(optionInput)
    });
  }
}
