import { Controller } from "stimulus"
import { filterInt } from "./helpers/application_helper.js"

export default class extends Controller {
  static targets = [ "quantity" ]

  change_quantity() {
    if (isNaN(filterInt(this.quantityTarget.value))) {
      this.quantityTarget.value = 1
    } else if (filterInt(this.quantityTarget.value) < 0) {
      this.quantityTarget.value = 1
    }
  }
}
