/* eslint no-console:0 */

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Rails
import Rails from "@rails/ujs"
//import Turbolinks from 'turbolinks'
//import * as ActiveStorage from "@rails/activestorage"
//require("channels")
//require("trix")
//require("@rails/actiontext")

//import "trix/dist/trix.css"

// Libs 
import jQuery from "jquery"

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"
//import "bootstrap/dist/css/bootstrap-theme"

import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel"

//import "nouislider"
//import "nouislider/distribute/nouislider.css"

import svg4everybody from "svg4everybody"

import "../src/oak/application_oak"
import "images"
import "images/oak"
import "fonts"

import "flag-icon-css/css/flag-icon.css"

//Theme
//function importAll (r) {
//  r.keys().forEach(r);
//}

// Theme external
//importAll(require.context("../src/", true, /\.js$/));
import "../src/oak/main"
import "../src/oak/number"
import "../src/oak/header"

// Extra
import "./gems.js.erb"

// Logic
import "controllers"

import ahoy from "ahoy.js"
import { screenResolution } from "../helpers/application_helper.js"

ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/dracaena/visits",
  eventsUrl: "/dracaena/events",
  page: null,
  platform: "web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: {},
  visitParams: {screen_resolution: screenResolution()},
  withCredentials: false
})
ahoy.trackAll()

svg4everybody()

Rails.start()
//Turbolinks.start();
//ActiveStorage.start()
