import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "card", "errors" ]

  connect() {
    this.stripe    = Stripe(this.data.get("public-key"))
    const elements = this.stripe.elements()
    const style    = JSON.parse(this.data.get("style"))

    this.card = elements.create("card", { style: style })
    this.card.mount(this.cardTarget)
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ""
    }
  }

  stripeTokenHandler(token) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "stripeToken")
    hiddenInput.setAttribute("value", token.id)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.submit()
  }

  stripePaymentIntentHandler(token) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "payment_intent[identifier]")
    hiddenInput.setAttribute("value", token.id)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.submit()
  }

  submit(event) {
    event.preventDefault()

    const clientSecret = this.data.get("client-secret")
    const clientName = this.data.get("client-name")

    this.stripe.confirmCardPayment(
      clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {name: clientName}
        }
    }).then((result) => {
      if (result.error) {
        this.errorsTarget.textContent = result.error.message
        Rails.enableElement(document.querySelector(".cart__checkout-button"))
      } else {
        this.stripePaymentIntentHandler(result.paymentIntent)
      }
    })
  }
}
