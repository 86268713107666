import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "name",
    "companyName",
    "country",
    "street",
    "apt",
    "postcode",
    "city",
    "taxNumber"
  ]

  completeForm(event) {
    this.nameTarget.value        = event.currentTarget.dataset.name
    this.companyNameTarget.value = event.currentTarget.dataset.companyname
    this.streetTarget.value      = event.currentTarget.dataset.street
    this.aptTarget.value         = event.currentTarget.dataset.apt
    this.postcodeTarget.value    = event.currentTarget.dataset.postcode
    this.cityTarget.value        = event.currentTarget.dataset.city

    this.inputOrSelect()

    if (this.hastaxNumberTarget) {
      this.taxNumberTarget.value   = event.currentTarget.dataset.taxnumber
    }
  }

  inputOrSelect() {
    if (this.countryTarget.tagName === 'INPUT') {
      this.countryTarget.value = event.currentTarget.dataset.country
    } else if (this.countryTarget.tagName === 'SELECT') {
      this.countryTarget.value = event.currentTarget.dataset.country
      this.countryTarget.dispatchEvent(new Event("chosen:updated"))
      this.countryTarget.dispatchEvent(new Event("change"))
    }
  }
}
